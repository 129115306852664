import React from 'react'
import PropTypes from 'prop-types'
import { useMediaQueryContext } from 'components/media-query-provider/media-query-provider'
import { SectionLayout } from 'components/section-layout/section-layout'
import { TabsItem, Tabs } from 'components/tabs/tabs'
import styled from 'styled-components'
import { mediaBreakpointUpLg } from 'theme/breakpoints'

type CategoryPageLayoutProps = {
	title: PropTypes.ReactNodeLike
	news: PropTypes.ReactNodeLike[]
	showHighlightArticles: boolean
	pagination: PropTypes.ReactNodeLike
	mostRecentTitle: string
	mostRecentItems: PropTypes.ReactNodeLike[]
	mostReadTitle: string
	mostReadItems: PropTypes.ReactNodeLike[]
	verticalAdvertisement: PropTypes.ReactNodeLike
	horizontalAdvertisement: PropTypes.ReactNodeLike
	head: PropTypes.ReactNodeLike
}

const CategoryPageTitle = styled.div`
	margin: 20px 0 14px;

	${mediaBreakpointUpLg} {
		margin: 34px 0 18px;
	}
`

const CategoryPagePagination = styled.div`
	margin: 6px 0 36px;

	${mediaBreakpointUpLg} {
		margin: 5px 0 30px;
	}
`

const CategoryPageTabs = styled.div`
	${mediaBreakpointUpLg} {
		margin-top: -10px;
	}
`

export const CategoryPageLayout = ({
	title,
	news,
	showHighlightArticles,
	pagination,
	mostRecentTitle,
	mostRecentItems,
	mostReadTitle,
	mostReadItems,
	verticalAdvertisement,
	horizontalAdvertisement,
	head,
}: CategoryPageLayoutProps) => {
	const { isMobile } = useMediaQueryContext()
	const highlightedNews = news.slice(0, 3)
	const remainingNews = news.slice(3)
	const allNews = showHighlightArticles ? remainingNews : news

	const mostRecentAndReadTabItems: TabsItem[] = [
		{
			title: mostRecentTitle,
			content: mostRecentItems,
		},
		{
			title: mostReadTitle,
			content: mostReadItems,
		},
	]

	return (
		<>
			{head}
			<SectionLayout noPaddingTop noPaddingBottom>
				<div className="container">
					<div className="row">
						<div className="col-md-10 offset-md-1 col-lg-12 offset-lg-0">
							<CategoryPageTitle>{title}</CategoryPageTitle>
						</div>
					</div>
					{showHighlightArticles && (
						<div className="row">
							<div className="col-md-10 offset-md-1 col-lg-8 offset-lg-0 highlighted">{highlightedNews[0]}</div>
							<div className="col-md-10 offset-md-1 col-lg-4 offset-lg-0">
								<div className="row">
									<div className="col-12 highlighted">{highlightedNews[1]}</div>
									<div className="col-12 highlighted">{highlightedNews[2]}</div>
								</div>
							</div>
						</div>
					)}
					<div className="row">
						<div className="col-md-10 offset-md-1 col-lg-8 offset-lg-0 mt-down-md-14">
							<div className="row">
								{allNews.map((newsItem, index) => (
									<div key={index} className="col-12">
										{newsItem}
									</div>
								))}
							</div>
							{pagination && (
								<div className="row">
									<div className="col-12">
										<CategoryPagePagination>{pagination}</CategoryPagePagination>
									</div>
								</div>
							)}
						</div>
						<div className="col-md-10 offset-md-1 col-lg-4 offset-lg-0">
							<div className="row">
								<div className={`col-12 mb-30 mb-xs-24 ${isMobile ? 'order-2' : 'order-1'}`}>
									<CategoryPageTabs>
										<Tabs tabs={mostRecentAndReadTabItems} />
									</CategoryPageTabs>
								</div>
								{verticalAdvertisement && (
									<div className={`col-12 mb-30 mb-xs-24 ${isMobile ? 'order-1' : 'order-2'}`}>
										{verticalAdvertisement}
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</SectionLayout>
			{horizontalAdvertisement && (
				<SectionLayout noPaddingTop small>
					<div className="container">
						<div className="row">
							<div className="col-md-10 offset-md-1 col-lg-12 offset-lg-0">{horizontalAdvertisement}</div>
						</div>
					</div>
				</SectionLayout>
			)}
		</>
	)
}
