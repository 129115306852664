import React from 'react'
import { NextSeo } from 'next-seo'
import { CategoryPageLayout } from 'components/layouts/category-page-layout/category-page-layout'
import { Pagination } from 'components/pagination/pagination'
import { Advertisement, OrientationTypes } from 'components/advertisement/advertisement'
import { PageTitle } from 'components/page-title/page-title'
import {
	ArticleSimpleDataFragment,
	ArticlesCategoryPageDataFragment,
	ArticlesSubCategoryPageDataFragment,
	ArticlesHighlightsPageDataFragment,
	ArticlesIndexPageDataFragment,
	PhotoGallerySimpleDataFragment,
	PhotoGalleriesCategoryPageDataFragment,
	PhotoGalleriesSubCategoryPageDataFragment,
	PhotoGalleriesHighlightsPageDataFragment,
	PhotoGalleriesIndexPageDataFragment,
	VideoSimpleDataFragment,
	VideosCategoryPageDataFragment,
	VideosSubCategoryPageDataFragment,
	VideosHighlightsPageDataFragment,
	VideosIndexPageDataFragment,
	TopicIndexPageDataFragment,
} from 'generated/sdk'
import { useMediaQueryContext } from 'components/media-query-provider/media-query-provider'
import { useSeoContext } from 'components/seo-provider/seo-provider'
import { categoryPageDataMapper, categoryDataMapper, subCategoryDataMapper, getRenditionUrl } from 'utils/data'
import { MostReadItemRow } from 'components/most-read/most-read'
import { NewsCardFeatured } from 'components/news-card-featured/news-card-featured'
import { NewsCardSimple } from 'components/news-card-simple/news-card-simple'
import { NewsCardSizes, NewsCardTypes } from 'components/news-card/news-card'
import { useRouter } from 'next/router'

type CategoryPageProps = {
	pageData:
		| ArticlesCategoryPageDataFragment
		| ArticlesSubCategoryPageDataFragment
		| ArticlesHighlightsPageDataFragment
		| ArticlesIndexPageDataFragment
		| PhotoGalleriesCategoryPageDataFragment
		| PhotoGalleriesSubCategoryPageDataFragment
		| PhotoGalleriesHighlightsPageDataFragment
		| PhotoGalleriesIndexPageDataFragment
		| VideosCategoryPageDataFragment
		| VideosSubCategoryPageDataFragment
		| VideosHighlightsPageDataFragment
		| VideosIndexPageDataFragment
		| TopicIndexPageDataFragment
}

type CategoryPageCategory = {
	label: string
	href: string
	parent?: {
		label: string
		href: string
	} | null
}

const CategoryPage = ({ pageData }: CategoryPageProps) => {
	const { isMobile } = useMediaQueryContext()
	const seoData = useSeoContext()
	const { query } = useRouter()

	const showHighlightArticles =
		pageData.__typename !== 'TopicIndexPage' && pageData.articles.pagination.currentPage === 1 && !query.before

	let pageCategory: CategoryPageCategory | null = null
	if (
		pageData.__typename === 'ArticlesSubCategoryIndexPage' ||
		pageData.__typename === 'PhotoGalleriesSubCategoryIndexPage' ||
		pageData.__typename === 'VideosSubCategoryIndexPage'
	) {
		pageCategory = subCategoryDataMapper(pageData.category)
	} else if (
		pageData.__typename === 'ArticlesCategoryIndexPage' ||
		pageData.__typename === 'PhotoGalleriesCategoryIndexPage' ||
		pageData.__typename === 'VideosCategoryIndexPage'
	) {
		pageCategory = categoryDataMapper(pageData.category)
	}

	const title =
		pageCategory !== null ? (
			<PageTitle {...(pageCategory.parent && { prependTitle: pageCategory.parent.label })}>
				{pageCategory.label ? pageCategory.label : pageData.title}
			</PageTitle>
		) : (
			<PageTitle>{pageData.title}</PageTitle>
		)

	const news = (pageData.articles.items as Array<
		ArticleSimpleDataFragment | PhotoGallerySimpleDataFragment | VideoSimpleDataFragment
	>).map((article, index) => {
		const { __typename, id, title, description, url, thumbnail, categoryPage, timestamp } = article
		const isLarge = index === 0
		const isHighlighted = index < 3 && showHighlightArticles
		let type: NewsCardTypes
		switch (__typename) {
			case 'PhotoGallery':
				type = NewsCardTypes.photoGallery
				break
			case 'Video':
				type = NewsCardTypes.video
				break
			default:
				type = NewsCardTypes.simple
		}
		const props = {
			key: id,
			id: id || index,
			url,
			title,
			category: categoryPageDataMapper(categoryPage),
			description,
			timestamp: timestamp || '',
			thumbnail: {
				src: getRenditionUrl(isLarge && !isMobile ? thumbnail.medium : thumbnail.small),
				srcRetina: getRenditionUrl(isLarge && !isMobile ? thumbnail.large : thumbnail.medium),
				srcPlaceholder: thumbnail.placeholderHash,
				alt: thumbnail.title,
			},
		}

		return isHighlighted ? (
			<NewsCardFeatured {...props} {...(isLarge && { size: NewsCardSizes.large })} type={type} hideTag />
		) : (
			<NewsCardSimple type={type} {...props} />
		)
	})

	const hasPagination = pageData.articles.pagination.totalPages > 1

	const mostRecentItems = pageData.mostRecentArticles.map(({ url, title, categoryPage, timestamp }) => ({
		url,
		title,
		category: categoryPageDataMapper(categoryPage),
		timestamp,
	}))

	const mostReadItems = pageData.mostReadArticles.map(({ url, title, categoryPage, timestamp }) => ({
		url,
		title,
		category: categoryPageDataMapper(categoryPage),
		timestamp,
	}))

	const verticalAdvertisementItems = pageData.advertisementsVertical.map(item => {
		return {
			src: getRenditionUrl(isMobile ? item.imageMobile.small : item.imageDesktop.small),
			srcRetina: getRenditionUrl(isMobile ? item.imageMobile.medium : item.imageDesktop.medium),
			srcPlaceholder: isMobile ? item.imageMobile.placeholderHash : item.imageDesktop.placeholderHash,
			alt: item.caption || item.imageMobile.title || item.imageDesktop.title,
			url: item.linkUrl || item.linkPage?.url || '',
		}
	})

	const horizontalAdvertisementItems = pageData.advertisementsHorizontal.map(item => {
		return {
			src: getRenditionUrl(isMobile ? item.imageMobile.small : item.imageDesktop.large),
			srcRetina: getRenditionUrl(isMobile ? item.imageMobile.medium : item.imageDesktop.extraLarge),
			srcPlaceholder: isMobile ? item.imageMobile.placeholderHash : item.imageDesktop.placeholderHash,
			alt: item.caption || item.imageMobile.title || item.imageDesktop.title,
			url: item.linkUrl || item.linkPage?.url || '',
		}
	})

	return (
		<CategoryPageLayout
			title={title}
			news={news}
			showHighlightArticles={showHighlightArticles}
			pagination={hasPagination ? <Pagination {...pageData.articles.pagination} /> : null}
			mostRecentTitle={pageData.mostRecentTitle}
			mostRecentItems={mostRecentItems.map((item, index) => MostReadItemRow(item, index))}
			mostReadTitle={pageData.mostReadTitle}
			mostReadItems={mostReadItems.map((item, index) => MostReadItemRow(item, index))}
			verticalAdvertisement={
				verticalAdvertisementItems.length > 0 && (
					<Advertisement items={verticalAdvertisementItems} orientation={OrientationTypes.vertical} />
				)
			}
			horizontalAdvertisement={
				horizontalAdvertisementItems.length > 0 && (
					<Advertisement items={horizontalAdvertisementItems} orientation={OrientationTypes.horizontal} />
				)
			}
			head={
				<NextSeo
					title={pageData.seoTitle || pageData.title}
					description={pageData.searchDescription || seoData.description}
					openGraph={{
						title: pageData.seoTitle || pageData.title,
						description: pageData.searchDescription || seoData.description,
						url: pageData.fullUrl || '',
						type: 'website',
						images: [
							{
								url: seoData.sharableImage.rendition?.url || '',
								alt: seoData.title,
							},
						],
					}}
					twitter={{
						cardType: 'summary_large_image',
					}}
					{...(seoData.tags.length > 0 && {
						additionalMetaTags: [
							{
								name: 'keywords',
								content: seoData.tags.map(item => item.name).join(', '),
							},
						],
					})}
				/>
			}
		/>
	)
}

export default CategoryPage
